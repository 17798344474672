













































































































import { Fornecedor, Porto } from "@/core/models/geral";
import { Equipamento, RegistroOperacionalEquipamento } from "@/core/models/operacional";
import { LocalOperacao } from "@/core/models/operacional/LocalOperacao";
import { PageBase } from "@/core/models/shared";
import { FornecedorService, PortoService } from "@/core/services/geral";
import { EquipamentoService, RegistroOperacionalService } from "@/core/services/operacional";
import { AlertSimpleErr } from "@/core/services/shared/AlertService";
import { Component, Prop, Watch} from "vue-property-decorator";

@Component
export default class ListaRegistroOperacional extends PageBase {
    
    item = new RegistroOperacionalEquipamento();
    service = new RegistroOperacionalService();

    dialogCadastro: boolean = false;
    loading: boolean = false;
    totalLista: number = 0;
    lista: any[] = [];
    sheet: boolean = false;    
    options: any = {
        itemsPerPage: 15
    };

    fornecedorService = new FornecedorService()
    fornecedores: Fornecedor[] = [];
    isFornecedorLoading: boolean = false;
    onSearchFornecedor: any = null;

    equipamentoServicoService = new EquipamentoService();
    equipamentoServicos: Equipamento[] = [];
    isEquipamentoServicoLoading: boolean = false;
    onSearchEquipamentoServico: any = null;

    portoService: PortoService = new PortoService();
    isPortoLoading: boolean = false;
    portos: Porto[] = [];
    locaisOperacao: LocalOperacao[] = [];

    filtro: any = {
        numeroOs: null,
        numeroRo: null,        
        portoId: null,
        localOperacaoId: null,
        fornecedorId: null,
        equipamentoId: null
    }
    
    headers: any[] = [
        { text: '',value:'actions' ,sortable: false },
        { text: '#', value: 'sequencia', type: 'number'},
        { text: '# RO', value: 'registroOperacional.numero', type: 'number'},
        { text: 'Cliente', value: 'registroOperacional.proposta.cliente.nome'},
        { text: 'Porto', value: 'registroOperacional.porto.nome'},
        { text: 'Local Operação', value: 'registroOperacional.atracacao.localOperacao.nome'},
        { text: 'Equipamento', value: 'equipamento.nomeTipo'},
        { text: 'Fornecedor', value: 'fornecedor.nome'},
        { text: 'Quantidade', value: 'quantidade'},
        { text: 'Data/Hora Inicio', value: 'dataHoraInicio', type: 'date'},
        { text: 'Data/Hora Fim', value: 'dataHoraTermino', type: 'date'},
        { text: 'Observação', value: 'observacao'},
    ];

    mounted() { 
        this.isPortoLoading = true;
        this.portoService.ListarTudo("LocaisOperacao").then(
            res => {
                this.portos = res.data.items;
            }
        ).finally(() => {
            this.isPortoLoading = false;
        })
    }

    @Watch('filtro.portoId')
    WatchPorto(){
        if(this.filtro.portoId){
            let porto = this.portos.find(x => x.id == this.filtro.portoId)!;
            this.locaisOperacao = porto.locaisOperacao.filter(x => x.ativo == true);
        }
        else{
            this.locaisOperacao = [];
            this.filtro.localOperacaoId = null;
        }
    }

    @Watch('onSearchFornecedor')
    SearchFornecedor (val: string) {
        if (this.isFornecedorLoading) return;
        if(this.filtro.fornecedorId) return;
        if (!val) return;
        this.isFornecedorLoading = true
        this.fornecedorService.AutoComplete(val).then(
        res => {
            this.fornecedores = res.data;
        },
        err => AlertSimpleErr("Aviso!", err)
        )
        .finally(() => (this.isFornecedorLoading = false));
    }

    @Watch('onSearchEquipamentoServico')
    SearchEquipamentoServico(val:string){
        if(this.isEquipamentoServicoLoading)return;
        if(this.filtro.equipamentoServicoId)return;        
        if(!val) return;
        this.isEquipamentoServicoLoading = true;
        this.equipamentoServicoService.AutoComplete(val).then(
        res => {
            this.equipamentoServicos = res.data;
        },
        err => AlertSimpleErr("Aviso!", err)
        )
        .finally(() => (this.isEquipamentoServicoLoading = false));
    } 

    @Watch('options', { deep: true })
    Atualizar(isFiltro: boolean = true){        

        const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
        this.loading = true;
        this.sheet = false;        

        this.service.Equipamentos(page, itemsPerPage, sortBy, sortDesc, search, this.headers, this.filtro).then(
            res => {
                this.lista = res.data.items;
                this.totalLista = res.data.count;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.loading = false));
    }

    AbrirDialogCadastro(item: RegistroOperacionalEquipamento){
        this.service.Equipamento(item.id).then(
            res => {
                this.item = res.data;
                this.dialogCadastro = true;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }
}
